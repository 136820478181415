.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 185px calc(100% - 185px);
  /* border: 2px solid red; */
  background-image: url("./images/background2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App>header {
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  /* border: 2px solid purple; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.App header h1, .App header h2 {
  font-family: 'Questrial', sans-serif;
}

.App header h1 {
  font-size: 1.90em;
  margin: 5px;
}

.App header h2 {
  font-size: 0.75em;
}

.App header section {
  margin: 2px;
  text-align: center;
}


@media only screen and (max-width: 399px){
  .App>header h1 {
      font-size: 1.90em;
  }
  .App>header h2 {
    font-size: 0.75em;
  }
}

@media only screen and (min-width: 400px) and (max-width: 750px){
  .App>header h1 {
      font-size: 2.5em;
  }
  .App>header h2 {
    font-size: 0.95em;
  }
}

@media only screen and (min-width: 751px){
  .App>header h1 {
      font-size: 2.75em;
  }
  .App>header h2 {
    font-size: 1.0em;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #314458;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #314458;
}