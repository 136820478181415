.PageWrapper {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow-y: scroll;

    /* border: 1px solid blue; */
    padding: 5px;
    margin: 0;
}

.PageContentWrapper, .HomePageWrapper {
    text-align: center;
    font-weight: bold;
}

.HomePageWrapper {
    height: 100%;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.HomePageWrapper h1 {
    font-size: 1.1em;
    margin: 2px;
}
.HomePageWrapper p {
    margin: 2px;
}

.WithBackground {
    width: 100%;
    padding: 5px;
    background-color: rgb(245, 245, 245);
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.25);
}

.WithBackground2 {
    max-width: 400px;
    padding: 0px;
    margin: 10px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50px calc(100% - 50px);
    
    background-color: whitesmoke;
    border: 2px solid rgb(56, 92, 131);
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    font-family: "Questrial", sans-serif;
}
.WithBackground2>h1{
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;    
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(83, 126, 171);
    border: none;
    color: whitesmoke;
    font-weight: 600;
}

.WithBackground2>p{
    padding: 10px;
}

#Instagram {
    width: 100%;
    max-width: 400px;
    margin: 25px auto;
    height: 85px;
    padding: 5px;
display: grid;
grid-template-columns: 80px calc(100% - 80px);
    background-color: whitesmoke;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 10px;
    text-decoration: none;
    color: black;
    font-family: "Questrial", sans-serif;
}

#Instagram>img{
    height: 75px;
    width: 75px;
}

#Instagram>h2{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25em;
    margin: 0;
    padding: 0;
}

#Instagram:hover {
    transition: all 500ms;
    transform: scale(1.05);
}
@media only screen and (max-width: 399px){

}

@media only screen and (min-width: 400px) and (max-width: 750px){
  .WithBackground {
     width: minmax(400px, 100%);
  }
}

@media only screen and (min-width: 751px){
    .WithBackground {
        width: 400px;
     }
}