/* barlow-condensed-regular - latin */

.Selected {
    background-color: white;
}

.Deselected {
    background-color: transparent;
}

.Selected, .Deselected {
    font-size: 1.15em;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    outline: none;
    border: none;
    padding: 10px;
    margin: 2px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
}

.Deselected:hover {
    cursor: pointer;
    background-color: rgba(245, 245, 245, 0.5);
}

.Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 399px){
    .Selected, .Deselected {
        font-size: 1em;
    }
}

@media only screen and (min-width: 400px) and (max-width: 750px){
    .Selected, .Deselected {
        font-size: 1.15em;
    }
}

@media only screen and (min-width: 751px){
    .Selected, .Deselected {
        font-size: 1.25em;
    }
}