.ContactPageContentWrapper {
  margin: 0 auto;
  min-width: 250px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Questrial", sans-serif;
}

.ContactForm {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.FullWidth {
  width: 100%;
}

.Header {
  margin: 0px auto;
  margin-bottom: 10px;
  padding: 10px 25px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  color: whitesmoke;
  background-color: rgb(83, 126, 171);
  text-align: center;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.75);
  font-weight: 500;
}
.ContactForm > section {
  margin: 10px 0px;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 2px solid rgba(45, 69, 95, 0.5);
  background-color: rgb(83, 126, 171);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}
.ContactForm > section > input, .ContactForm > section > textarea {
  height: 30px;
  width: 100%;
  border: none;
  padding: 5px;
}


.ContactForm > section > label {
  padding: 5px;
  margin: 0px;
  color: whitesmoke;
  font-weight: 500;
}

#messageBox {
  min-height: 100px;
  min-width: 100%;
  max-width: 100%;
}

#submitButton > button {
    padding: 10px 25px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border: 2px solid rgba(45, 69, 95, 0.5);
    background-color: rgb(83, 126, 171);
    color: whitesmoke;
    font-family: "Questrial", sans-serif;    
    font-size: 1em;
}

#submitButton > button:hover {
    cursor: pointer;
    background-color: rgb(87, 141, 199);
}

#submitButton {
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

@media only screen and (max-width: 399px) {
}

@media only screen and (min-width: 400px) and (max-width: 750px) {
    .ContactForm {
        max-width: 400px;
        margin: 0px auto;
    }
}

@media only screen and (min-width: 751px) {
    .ContactForm {
        max-width: 600px;
        margin: 0px auto;
    }
}
