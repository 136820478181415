.ServiceCard-Wrapper {
    width: calc(100% - 20px);
    max-width: 400px;
    height: fit-content;
    margin: 10px auto;
    display: grid;
    grid-gap: 5px;
    grid-template-rows: 40px calc(100% - 80px);
    grid-template-columns: 100%;
    border: 2px solid rgb(87, 141, 199);
    background-image: linear-gradient(whitesmoke, whitesmoke, rgba(245, 245, 245, 0.75));
    box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-family: 'Questrial', sans-serif;
}

.ServiceCard-Wrapper>section {
    padding: 15px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    line-height: 0%;
    display: grid;
    grid-template-columns: calc(100% - 40px) 40px;
    border-bottom: 2px solid lightgray;
    background-color: rgb(87, 141, 199);
}

.ServiceCard-Wrapper>main {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ServiceCard-Wrapper>section>h1, .ServiceCard-Wrapper>section>h2 {
    display: flex; 
    align-items: center;
    justify-content: center;
    margin: 0px;
    width: 100%;
    height: 100%;
    color: whitesmoke;
}

.ServiceCard-Wrapper>section>h1 {
    font-size: 1em;
}

.ServiceCard-Wrapper>section>h2 {
    font-size: 1em;
}

.ServiceCard-Wrapper>main>h3 {
    font-size: 1em;
    text-align: start;
    margin: 0px;
    padding: 15px;

}

.ServiceCard-Wrapper>main>button {
    color: whitesmoke;
    font-weight: bold;
    background-color: #457c52;
    padding: 10px;
    border-radius: 5px;
    margin: 5px auto;    
}
.ServiceCard-Wrapper>main>button:hover {
    cursor: pointer;
    background-color: #5e9c6c;
}