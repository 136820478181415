.Slideshow-Wrapper {
  margin: 10px auto;
  width: 200px;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 50px 200px 50px;
  grid-template-columns: 100%;
}

.Slideshow-picture {
  height: 200px;
  width: 200px;
  margin: 10px auto;
  border: 2px solid lightgray;
  border-radius: 10px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Thumbnail-Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Slideshow-thumbnail {
  height: 40px;
  width: 40px;
  margin: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid lightgray;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
}

.Slideshow-thumbnail:hover {
  cursor: pointer;
  transform: scale(1.1);
  border: 2px solid lightgray;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}

.Slideshow-Label {
    width: 100%;
    height: 40px;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 40px calc(100% - 40px);
    position: relative;
    bottom: -10px;
    left: -20px;
    transform: scale(1.15) rotateZ(-5deg);
    z-index: 0;
}

.Slideshow-Label>div {
    width: 70px;
    height: 70px;
    display: flex;
    position: relative;
    top: -10px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 3px solid rgb(87, 141, 199);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    background-color:whitesmoke;
    background-image: url('../../images/tri-logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotateZ(-5deg);
}
.Slideshow-Label>h3 {
    width: 100%;
    height: 50px;
    margin: auto;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 399px) {
  .Slideshow-Label>h3 {
    font-size: 1em;
    height: 35px;
  }

  .Slideshow-Label>div {
    height: 50px;
    width: 50px;
    top: -5px;
    left: 5px;
  }

  .Slideshow-Wrapper {
    margin: 0px auto;
  }
}

@media only screen and (min-width: 400px) and (max-width: 750px) {
  .Slideshow-Wrapper {
    width: 250px;
    grid-template-rows: 50px 250px 60px;
  }

  .Slideshow-picture {
    width: 250px;
    height: 250px;
  }

  .Slideshow-thumbnail {
    width: 50px;
    height: 50px;
  }

  .Slideshow-Label>h3 {

  }
}

@media only screen and (min-width: 751px) {
  .Slideshow-Wrapper {
    width: 300px;
    grid-template-rows: 50px 300px 80px;
  }

  .Slideshow-picture {
    width: 300px;
    height: 300px;
  }

  .Slideshow-thumbnail {
    width: 70px;
    height: 70px;
  }

  .Slideshow-Label>h3 {

  }
}
