.Modal-Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    padding-top: 100px;
    background-color: rgba(0,0,0,0.5);
}

.Modal-Hidden {
    display: none;
}

.Modal-Wrapper>main {
    display: grid;
    width: calc(100% - 20px);
    max-width: 400px;
    margin: 0 auto;
    grid-template-rows: 60px calc(100% - 40px);
    grid-template-columns: 100%;

    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;

    box-shadow: 2px 2px 25px rgba(0,0,0,0.75);
}

.Modal-Wrapper>main>h1 {
    background-color: rgb(87, 141, 199);
    color: whitesmoke;
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25em;
}

.Modal-Wrapper>main>form {
    width: 100%;
    height: fit-content;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    padding: 25px;
    background-color: whitesmoke;
    border-bottom-right-radius: 10px;
}

.Modal-Wrapper>main>form * {
    margin: 5px;
    width: calc(100% - 10px);
    max-width: calc(100% - 10px);
    position: relative;
}

.Modal-Wrapper>main>form label {
    width: 100%;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Modal-Wrapper>main>form textarea {
    min-height: 100px;
    max-height: 300px;
    padding: 5px;
}

.Modal-Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Modal-Buttons button {
    height: 30px;
    width: 40%;
    border-radius: 5px;
    padding: 0px 10px;
    font-weight: bold;
}

.Modal-Buttons button:hover {
    cursor: pointer;
}