.AboutUs-Header {
    max-width: 400px;
    padding: 10px;
    margin: 0 auto;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
    background-color: whitesmoke;
    font-family: "Questrial", sans-serif; 
}

#AboutUs-h1 {
    font-size: 1.5em;
    border-bottom: 1px solid darkgray;
}
#AboutUs-h2 {
    font-size: 1.25em;
}
#AboutUs-p {
    font-size: 1em;
    padding: 0px 15px;
}

#PupsTitle {
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    margin: 20px auto;
    width: 100%;
    max-width: 400px;
    background-color: whitesmoke;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
    position: relative;
}

#PupsTitle::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 400px;
    top: -10px;
    left: 0px;
    border-top: 2px solid whitesmoke;
}

.AboutPage-Dogs {
    margin: 5px auto;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 4px solid whitesmoke;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.5);
}

.AboutPage-Section {
    max-width: 400px;
    background-color: whitesmoke;
    margin: 10px auto;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
}
.DogCard {
    border-top-left-radius: 10px;
    padding: 10px;
    width: 100%;
    background-color: rgb(83, 126, 171);
}

.AboutPage-Section>p{
    padding: 10px;
}

.AboutPage-Section>section>h3, .AboutPage-Section>section>h4{
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: "Questrial", sans-serif; 
    color: whitesmoke;
}

.AboutPage-Section>section>h3{
    font-size: 1.25em;
}

.AboutPage-Section>section>h4{
    font-size: 1em;
}

#sadie {
    background-image: url('../images/sadie2.jpg');
    background-size: cover;
}

#toastie {
    background-image: url('../images/toastie2.jpg');
    background-size: cover;
}

#dundee {
    background-image: url('../images/dundee.jpg');
    background-size: cover;
}